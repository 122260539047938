import { pick } from 'lodash';

import { FlowDetailsResponse } from './flow-details.model';
import { EventTriggerResponseModel } from './new-flow-event-trigger.model';

export class FlowDescriptionTabModel {
  flowID: number;
  brandID: number;
  flowName: string;
  description: string;
  categoryID: number;
  subcategoryID: number;
  productID: number;
  creationDate: Date;
  statusID: number;
  status: string;
  priority: number;
  enabled: boolean;
  parentFlowID: boolean;
  parentFlowName: string;
  flowTriggerID: number;
  eventTrigger: EventTriggerResponseModel;
  hasHistory: boolean;
  onEditMode?: boolean;
  onEditUserID?: number;
  onEditStartedAt?: string;
  version?: number; // del?
  versionSource?: string; // del?
  brandName?: string = null; // del?
  categoryName: string = null; // del?

  static fromRaw(flowitem: FlowDetailsResponse, statuses) {
    return {
      flowID: flowitem.id,
      productID: null,
      ...pick(flowitem, [
        'brandID',
        'flowName',
        'description',
        'categoryID',
        'subcategoryID',
        'productID',
        'creationDate',
        'statusID',
        'status',
        'priority',
        'enabled',
        'parentFlowID',
        'parentFlowName',
        'flowTriggerID',
        'eventTrigger',
        'hasHistory',
        'onEditMode',
        'onEditUserID',
        'onEditStartedAt',
      ]),
      creationDate: flowitem.creationDate ? new Date(flowitem.creationDate) : null,
      status: statuses[flowitem.statusID]?.name ?? 'unknown',
      version: 1,
      versionSource: 'Current',
    };
  }
}
