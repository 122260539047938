import { NbMenuItem } from '@nebular/theme';

import { PermissionsEnum } from '../auth/models/permissions.model';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Smart Flow',
    expanded: true,
    icon: { icon: 'flow-chart', pack: 'bia-icons' },
    link: '/smart-flow',
    pathMatch: 'prefix',
    children: [
      {
        title: 'Dashboard',
        link: '/smart-flow/dashboard',
        data: {
          permissions: [PermissionsEnum.MONITORING_VIEW_DASHBOARD],
        },
      },
      {
        title: 'Flow Library',
        link: '/smart-flow/flow-library',
        data: {
          permissions: [PermissionsEnum.MONITORING_VIEW_FLOW_LIBRARY],
        },
      },
    ],
  },
  {
    title: 'CRM',
    icon: { icon: 'dashboard', pack: 'bia-icons' },
    children: [
      {
        title: 'Flow bulk edit',
        link: '/crm/flow-bulk-edit',
        data: {
          permissions: [PermissionsEnum.CRM_FLOW_BULK_UPDATE],
        },
      },
    ],
  },
  {
    title: 'KYC',
    icon: { icon: 'kyc', pack: 'bia-icons' },
    children: [
      {
        title: 'Dashboard',
        link: '/kyc/dashboard',
        data: {
          permissions: [PermissionsEnum.KYC_VIEW_DASHBOARD],
        },
      },
      {
        title: 'User management',
        link: '/kyc/user-management',
        data: {
          permissions: [PermissionsEnum.KYC_VIEW_USER_MANAGEMENT],
        },
      },
      {
        title: 'Verifications',
        link: '/kyc/verifications-management',
        data: {
          permissions: [PermissionsEnum.KYC_VIEW_VERIFICATIONS_MANAGEMENT],
        },
      },
      {
        title: 'Review verifications',
        link: '/kyc/review-verifications',
        data: {
          permissions: [PermissionsEnum.KYC_VIEW_REVIEW_VERIFICATIONS],
        },
      },
      {
        title: 'Manual verifications',
        link: '/kyc/manual-verifications',
        data: {
          permissions: [PermissionsEnum.KYC_VIEW_MANUAL_VERIFICATIONS],
        },
      },
    ],
  },
  {
    title: 'RPA',
    icon: { icon: 'robot', pack: 'bia-icons' },
    data: {
      permissions: [PermissionsEnum.RPA_VIEW_TASK_MANAGEMENT],
    },
    children: [
      {
        title: 'Tasks management',
        link: '/rpa/tasks-management',
        data: {
          permissions: [PermissionsEnum.RPA_VIEW_TASK_MANAGEMENT],
        },
      },
    ],
  },
  {
    title: 'Reports',
    icon: { icon: 'report', pack: 'bia-icons' },
    link: '/reports',
    pathMatch: 'prefix',
    children: [
      {
        title: 'Flow reports',
        link: '/reports/flow',
        data: {
          permissions: [PermissionsEnum.REPORT_ACCESS_FLOW_REPORTS],
        },
      },
      {
        title: 'Business reports',
        link: '/reports/business',
        data: {
          permissions: [PermissionsEnum.REPORT_VIEW_BUSINESS_REPORTS],
        },
      },
    ],
  },
  // {
  //   title: 'Machine learning',
  //   icon: { icon:"machine-learning", pack: 'bia-icons' },
  //   children: [
  //   ],
  // },
  {
    title: 'Configuration',
    icon: { icon: 'configurations', pack: 'bia-icons' },
    link: '/configurations',
    pathMatch: 'prefix',
    children: [
      {
        title: 'Apps',
        link: '/configuration/connected-apps',
        data: {
          permissions: [PermissionsEnum.VIEW_CONNECTED_APPS],
        },
      },
      {
        title: 'Settings',
        link: '/configuration/settings',
        expanded: true,
        children: [
          {
            title: 'Time Zones',
            link: '/configuration/settings/timezones',
            data: {
              permissions: [PermissionsEnum.SETTINGS_VIEW_TIME_ZONE_SETTINGS],
            },
          },
          {
            title: 'Notifications',
            link: '/configuration/settings/notifications',
            data: {
              permissions: [
                PermissionsEnum.SETTINGS_VIEW_ERROR_ALERTS,
                PermissionsEnum.SETTINGS_VIEW_USAGE_ANOMALIES_ALERTS,
                PermissionsEnum.SETTINGS_VIEW_VOLUME_CHECKS_ALERTS,
              ],
            },
          },
          {
            title: 'Root system log',
            link: '/configuration/settings/system-log',
            data: {
              isRootRoleRequired: true,
            },
          },
        ],
        data: {
          permissions: [
            PermissionsEnum.SETTINGS_VIEW_TIME_ZONE_SETTINGS,
            PermissionsEnum.SETTINGS_VIEW_ERROR_ALERTS,
            PermissionsEnum.SETTINGS_VIEW_USAGE_ANOMALIES_ALERTS,
            PermissionsEnum.SETTINGS_VIEW_VOLUME_CHECKS_ALERTS,
          ],
        },
      },
      {
        title: 'Users',
        link: '/configuration/users',
        data: {
          permissions: [PermissionsEnum.PERMISSION_SEARCH_USER],
        },
      },
      {
        title: 'Manage API key',
        link: '/configuration/manage-api-key',
        data: {
          permissions: [PermissionsEnum.PERMISSION_VIEW_API_TOKENS],
        },
      },
      {
        title: 'Actions&Events management',
        link: '/configuration/actions-events-management',
        data: {
          isRootRoleRequired: true,
        },
      },
      {
        title: 'Client management',
        link: '/configuration/client-management',
        data: {
          isRootRoleRequired: true,
        },
      },
    ],
  },
  // {
  //   title: 'Auth',
  //   icon: 'lock-outline',
  //   children: [
  //     {
  //       title: 'Login',
  //       link: '/auth/login',
  //     },
  //     {
  //       title: 'Register',
  //       link: '/auth/register',
  //     },
  //     {
  //       title: 'Request Password',
  //       link: '/auth/request-password',
  //     },
  //     {
  //       title: 'Reset Password',
  //       link: '/auth/reset-password',
  //     },
  //   ],
  // },
  {
    title: 'Support',
    icon: { icon: 'support', pack: 'bia-icons' },
  },
  {
    title: 'Logout',
    icon: { icon: 'logout', pack: 'bia-icons' },
  },
];
